import React, { useEffect, useRef, useState } from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useCustomer } from "../products/helperFunctions/CustomerHook";
import globalVariable from "../../../globalVariable";
import Cookies from "js-cookie";
import "../../../assets/css/pages/profile.css";

import dummyProfileImage from "../../../assets/images/other/dummyProfile.png";
import Banner from "../../components/Banner";
import useScrollToTop from "../../../hooks/UseScrollToTop";

import pencilImg from "../../../assets/icons/pencil_black.png";

const RenderInput = ({
  givenName,
  givenState,
  givenStateSetter,
  mdVal,
  type,
  isrequired,
  givenInputClasses,
}) => (
  <div className={`mb-5 col-md-${mdVal}`}>
    <label htmlFor="exampleInputEmail1" className="form-label profile_lable">
      {givenName}
      {isrequired && (
        <span style={{ color: "red", fontWeight: "bold" }}> * </span>
      )}
    </label>
    <input
      type={type}
      value={givenState}
      disabled
      onChange={(e) => {
        givenStateSetter(e.target.value);
      }}
      required={isrequired}
      className={`${givenInputClasses ? givenInputClasses : ""} form-control`}
      id="exampleInputEmail1"
      aria-describedby="emailHelp"
      placeholder="_"
    />
  </div>
);

const MyProfile = () => {
  const imageRef1 = useRef();
  const navigate = useNavigate();
  const { customerData, updateCustomerData } = useCustomer();

  useScrollToTop();
  // ================================= local states =================================
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [country, setcountry] = useState("");
  const [street, setstreet] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  // ================================= local states =================================
  const shippingDetailObj = {
    firstName,
    lastName,
    country,
    street,
    city,
    state,
    pincode,
    phone,
    email,
  };

  const handleLogout = () => {
    const userConfirmed = window.confirm("Are you sure you want to Logout?");

    if (userConfirmed) {
      sessionStorage.clear();
      Cookies.set("customerToken", "", { expires: 365 });
      setTimeout(() => {
        alert("Logout successfully!");

        setTimeout(() => {
          window.location.pathname = "/";
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }, 1000);
      }, 2000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let form_data = new FormData();
    form_data.append("image", imageRef1.current.files[0]);
    form_data.append("addressObject", JSON.stringify(shippingDetailObj));
    form_data.append("name", `${firstName} ${lastName}`);
    form_data.append("email", email);
    form_data.append("mobile", phone);

    fetch(`${process.env.REACT_APP_BACKEND_1}/customer/update-profile`, {
      method: "put",
      body: form_data,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          updateCustomerData();
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again.");
        console.log(err.message);
      });
    alert("ok");
  };

  useEffect(() => {
    if (!sessionStorage.getItem("accessToken")) {
      navigate("/login");
    }
    if (customerData) {
      if (customerData.mobile) {
        setphone(customerData.mobile);
      }
      if (customerData.addressObject) {
        if (customerData.addressObject.firstName) {
          setfirstName(customerData.addressObject.firstName);
          setlastName(customerData.addressObject.lastName);
          setcountry(customerData.addressObject.country);
          setstreet(customerData.addressObject.street);
          setcity(customerData.addressObject.city);
          setstate(customerData.addressObject.state);
          setpincode(customerData.addressObject.pincode);
          setemail(customerData.addressObject.email);
        }
      }
    }
  }, [customerData, navigate]);
  return (
    <div>
      <Header hideBookingSection={true} />
      <Banner title="Profile" showItemSlider={false} />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>My Profile</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container">
        <form onSubmit={handleSubmit}>
          {/* ================================= */}
          <div className="row my-5">
            <div className="col-lg-4">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div>
                    <div className="d-flex justify-content-center ">
                      <div
                        style={{
                          width: "60%",
                          border: "1px solid rgb(106,17,16)",
                        }}
                        className="p-2 rounded-circle"
                      >
                        <img
                          src={
                            customerData.image
                              ? customerData.image
                              : dummyProfileImage
                          }
                          style={{
                            width: "100%",
                            aspectRatio: "1",
                            borderRadius: "50%",
                            border: "1px solid rgb(106,17,16)",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <Link to="/orders">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 my-2"
                        style={{ backgroundColor: "rgb(106,17,16)" }}
                      >
                        My Orders
                      </button>
                    </Link>
                    <Link to="/cart">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 my-2"
                        style={{ backgroundColor: "rgb(106,17,16)" }}
                      >
                        Cart
                      </button>
                    </Link>
                    <Link to="/wishlist">
                      <button
                        type="button"
                        className="btn btn-secondary w-100 my-2"
                        style={{ backgroundColor: "rgb(106,17,16)" }}
                      >
                        Wishlist
                      </button>
                    </Link>
                    <button
                      type="button"
                      onClick={() => {
                        handleLogout();
                      }}
                      className="btn btn-danger w-100 my-2"
                      style={{ backgroundColor: "rgb(106,17,16)" }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div>
                <div className="col-md-12">
                  <div className="checkoutPage">
                    <div className="d-flex justify-content-between border-bottom pb-2 mb-4">
                      <h5>Personal Details</h5>
                      <Link to="/profile-update">
                        <img src={pencilImg} alt="edit" height={18} />
                      </Link>
                    </div>
                    <div className="row">
                      <RenderInput
                        givenName="First Name"
                        givenState={firstName}
                        givenStateSetter={setfirstName}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Last Name"
                        givenState={lastName}
                        givenStateSetter={setlastName}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Phone Number"
                        givenState={phone}
                        givenStateSetter={setphone}
                        mdVal={6}
                        type="number"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Email Address"
                        givenState={email}
                        givenStateSetter={setemail}
                        mdVal={6}
                        type="email"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Street Address"
                        givenState={street}
                        givenStateSetter={setstreet}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Country / Region"
                        givenState={country}
                        givenStateSetter={setcountry}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Town / City"
                        givenState={city}
                        givenStateSetter={setcity}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="State"
                        givenState={state}
                        givenStateSetter={setstate}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                      <RenderInput
                        givenName="Pincode"
                        givenState={pincode}
                        givenStateSetter={setpincode}
                        mdVal={6}
                        type="text"
                        isrequired={false}
                        givenInputClasses={"profile_input"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ================================= */}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default MyProfile;
