import globalVariable from "../globalVariable";
import { decryptToken } from "../helpers/EncryptionHandler";
import Cookies from "js-cookie";

export const VerifyToken = (callback) => {
  try {
    let loginToken = "";
    const storedToken = Cookies.get("customerToken");
    if (storedToken && storedToken !== "") {
      loginToken = storedToken;
    } else {
      const sesstionStoredToken = sessionStorage.getItem("myStoredLoginToken");
      if (sesstionStoredToken && sesstionStoredToken !== "") {
        Cookies.set("customerToken", sesstionStoredToken, { expires: 365 });
        loginToken = sesstionStoredToken;
      }
    }

    if (loginToken === "") {
      return null;
    }

    const Token = decryptToken(loginToken);

    fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/verifyToken`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          globalVariable.accessToken = Token;
          sessionStorage.setItem("accessToken", Token);
          if (v.data._id) {
            callback();
          }
        } else {
          if (v.detail) {
            console.log(v.detail);
          } else {
            console.log("something went wrong!");
          }
          Cookies.remove("customerToken");
        }
      })
      .catch((errors) => {
        console.log("====================================");
        console.log(errors);
        console.log("====================================");
      });
  } catch (error) {
    console.log("====================================");
    console.log(error.message);
    console.log("====================================");
  }
};
