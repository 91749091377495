import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { usePackageHook } from "../../../../redux/hooks/usePackageHook";
import EditedTextCmp from "../../../components/EditedTextCmp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllPackageAddOns } from "../../../../redux/action";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import globalVariable from "../../../../globalVariable";

const RenderPackageData = ({
  packageData,
  data,
  allPackageAddOns,
  setselectedCartPackage,
}) => {
  const [selectedArray, setselectedArray] = useState([]);

  const getTotalCost = (addOnArray, initialPrice) => {
    const addOnTotal = addOnArray.reduce((total, num) => {
      return total + Number.parseInt(num.price);
    }, 0);
    return addOnTotal + initialPrice;
  };

  const isIncluded = (givenArray, givenObj) => {
    return givenArray.filter((value) => value._id === givenObj._id);
  };

  const handleAddOrRemoveFromSelectedArray = (addOnObj) => {
    let isExist = isIncluded(selectedArray, addOnObj);
    if (isExist.length > 0) {
      let newArray = selectedArray.filter(
        (value) => value._id !== addOnObj._id
      );
      setselectedArray(newArray);
    } else {
      setselectedArray([...selectedArray, addOnObj]);
    }
  };

  return (
    <div className="front_package_inf packageDetails_box_item   ">
      <div className="packageDetails_box_item_item">
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id={`heading_faq_${packageData._id}`}
          >
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse_faq_${packageData._id}`}
              aria-expanded="true"
              aria-controls={`collapse_faq_${packageData._id}`}
            >
              <div className="d-flex justify-content-between w-100">
                <div>
                  <h5 className="mb-0 t_color1 tw_700">{packageData.title}</h5>

                  <p className="packageDetails_box_item_item_description">
                    <EditedTextCmp givenState={packageData.description} />
                  </p>
                </div>
                <div>
                  <h5 className="t_color1">
                    <b className="tw_700">
                      ₹{getTotalCost(selectedArray, packageData.price)}
                    </b>
                  </h5>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`collapse_faq_${packageData._id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading_faq_${packageData._id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div>
                <hr />
                <div>
                  <div>
                    <p>
                      <b className="t_color1 tw_700">
                        Number of Pandit: &nbsp;
                      </b>{" "}
                      <span className="tw_500">
                        {packageData.numberOfPandit}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <b className="t_color1 tw_700">Samagries: &nbsp;</b>{" "}
                      <span className="tw_500">{packageData.samagries}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <b className="t_color1 tw_700">
                        To be arranged by client: &nbsp;
                      </b>{" "}
                      <span className="tw_500">
                        {packageData.toBeArrageByClient}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <b className="t_color1 tw_700">Procedure: &nbsp;</b>
                      <EditedTextCmp givenState={packageData.procedure} />
                    </p>
                  </div>
                  <div>
                    <div>
                      <h5 className="t_color1 tw_700">Add Ons</h5>
                    </div>
                    <div className="package_modal_add_onn_div">
                      {allPackageAddOns.map((addOnnValue, addOnIndex) => (
                        <div key={`default-${addOnIndex}`} className="mb-3">
                          <div className="dfjcc">
                            <Form.Check
                              type={"checkbox"}
                              defaultChecked={
                                isIncluded(selectedArray, addOnnValue).length >
                                0
                              }
                              onClick={() => {
                                handleAddOrRemoveFromSelectedArray(addOnnValue);
                                data.setspackage("");
                              }}
                              id={`default-${addOnnValue._id}`}
                              label={`${addOnnValue.name}  `}
                            />
                            <span className="tw_700 t_color1">
                              ₹ {addOnnValue.price}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <span>
                    <button
                      type="button"
                      onClick={() => {
                        data.setspackage(packageData._id);
                        setselectedCartPackage({
                          puja: data.puja,
                          date: data.date,
                          location: data.address,
                          language: data.language,
                          package: packageData,
                          addonArray: selectedArray,
                          promoCode: {},
                        });
                      }}
                      className={`btn packageDetails_box_item_item_book_button ${
                        packageData._id === data.spackage
                          ? "packageDetails_box_item_item_book_button_selected"
                          : ""
                      } `}
                    >
                      {packageData._id === data.spackage
                        ? "Selected"
                        : "Select"}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function PujaModal({ visiblePujaModal, setvisiblePujaModal, data }) {
  const allPackageAddOns = useSelector((state) => state.packageAddOnsReducer);
  const dispatch = useDispatch();
  const { allPackages } = usePackageHook();
  const handleClose = () => setvisiblePujaModal(false);
  const [selectedCartPackage, setselectedCartPackage] = useState({});
  const customerData = useSelector((state) => state.customerDataReducer);
  const navigate = useNavigate();

  const handleMakeEnquiry = () => {
    // if (!data.spackage) {
    //   return alert("Please select an package!");
    // }
    // data.setvisibleLanguageModal(true);
    // navigate("/contact-us");
    window.location.pathname = "/contact-us";
  };

  const handleBookNow = () => {
    if (!data.spackage) {
      return alert("Please select an package!");
    }

    const formBody = new FormData();
    formBody.append("packageCartNewObj", JSON.stringify(selectedCartPackage));

    fetch(`${process.env.REACT_APP_BACKEND_1}/customer/package/cart/add`, {
      method: "POST",
      body: formBody,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Package added to cart successfully!");
          setvisiblePujaModal(false);
          setTimeout(() => {
            navigate("/cart");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }, 1000);
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            return alert("Package not added! Please try again.");
          }
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err.message);
        console.log("====================================");
        return alert("Package not added! Please try again.");
      });

    // data.setvisibleDateModal(true);
  };

  useEffect(() => {
    if (allPackageAddOns.length < 1) {
      dispatch(getAllPackageAddOns());
    }
  }, [allPackageAddOns, dispatch]);

  return (
    <div className="">
      <Modal
        // fullscreen={true}
        show={visiblePujaModal}
        onHide={handleClose}
        className="modal-content-transparent modal_content_container"
      >
        <Modal.Body>
          <div className="puja_modal_bg_primary puja_modal_border_style p-0">
            <div className="">
              <form className="container">
                <div className="my-2 mt-4 puja_modal_header_style ">
                  <h4
                    style={{ textAlign: "center", fontWeight: 700 }}
                    className="p-3 py-2"
                  >
                    {data.puja}
                  </h4>
                </div>
                <div className="mb-3">
                  <div className="packageDetails_box">
                    <div className="accordion" id="accordionExample">
                      {allPackages
                        .filter((value) => value.pujaCategory === data.puja)
                        .map((value, index) => {
                          return (
                            <RenderPackageData
                              key={index}
                              packageData={value}
                              data={data}
                              allPackageAddOns={allPackageAddOns}
                              setselectedCartPackage={setselectedCartPackage}
                            />
                          );
                        })}
                    </div>
                    <Modal.Footer
                      style={{ borderTop: 0, justifyContent: "center" }}
                    >
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={handleClose}
                        className="package_btn_1"
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary package_btn_2"
                        onClick={() => {
                          handleMakeEnquiry();
                        }}
                        type="button"
                      >
                        Make Enquiry
                      </Button>
                      {customerData && customerData.mobile && (
                        <Button
                          variant="primary package_btn_2"
                          onClick={handleBookNow}
                          type="button"
                        >
                          Add To Cart
                        </Button>
                      )}
                      {!customerData ||
                        (!customerData.mobile && (
                          <Button
                            variant="primary package_btn_2"
                            onClick={() => {
                              navigate("/login");
                            }}
                            type="button"
                          >
                            Login
                          </Button>
                        ))}
                    </Modal.Footer>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PujaModal;
