import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/main/home/Home";
import CancelationAndRefundPolicy from "./pages/main/cancellation_and_refund/CancelationAndRefundPolicy";
import Faq from "./pages/main/faq/Faq";
import TermsAndCondition from "./pages/main/terms_and_condition/TermsAndCondition";
import Products from "./pages/main/products/Products";
import Login from "./pages/other/auth/Login";
import Signup from "./pages/other/auth/Signup";
import Wishlist from "./pages/main/products/Wishlist";
import Cart from "./pages/main/products/Cart";
import Checkout from "./pages/main/products/Checkout";
import Order from "./pages/main/orders/Order";
import MyProfile from "./pages/main/profile/MyProfile";
import Pandit from "./pages/main/pandit/Pandit";
import PanditProfile from "./pages/main/pandit/subPages/PanditProfile";
import Blog from "./pages/main/blogs/Blog";
import About from "./pages/main/about/About";
import ContactUs from "./pages/main/contactus/ContactUs";
import BlogView from "./pages/main/blogs/BlogView";
import Galleries from "./pages/main/galleries/Galleries";
import { NewPanditRegistration } from "./pages/main/panditRegistration/NewPanditRegistration";
import MyProfileUpdate from "./pages/main/profile/MyProfileUpdate";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* login pages  */}
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      {/* login pages  */}
      <Route path="/products" element={<Products />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/orders" element={<Order />} />
      <Route path="/pandits" element={<Pandit />} />
      <Route path="/pandit/:id" element={<PanditProfile />} />
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogView />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/galleries" element={<Galleries />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/term-and-condition" element={<TermsAndCondition />} />
      <Route
        path="/cancellation-and-refund-policy"
        element={<CancelationAndRefundPolicy />}
      />
      <Route
        path="/new-pandit-registration"
        element={<NewPanditRegistration />}
      />
      <Route path="/profile-update" element={<MyProfileUpdate />} />
    </Routes>
  );
};

export default Routing;
